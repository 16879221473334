import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import {
  ParkingDetails,
  CompanyData,
  ParkingSummary,
  TicketData,
  PensionData,
  ParkingOverview, ParkingPricesOverview, NameValueData, UniqueParkingValidationData
} from '@core/parking/parking.interfaces';
import { map } from 'rxjs/operators';
import { HelperService } from '@shared/services/helper.service';
import moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class ParkingService {

  constructor(private httpClient: HttpClient) {}

  getParkingsInformation(): Observable<CompanyData> {
    return this.httpClient.get<CompanyData>(`${ environment.apiUrl }/parkings`);
  }

  getParkingOverview(parkingId: string, start: string, end: string): Observable<ParkingOverview> {
    const params = new HttpParams()
      .append('from', start)
      .append('to', end)
    ;
    return this.httpClient.get<ParkingOverview>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/overview`, { params }).pipe(
      map(overview => ParkingService._mapParkingOverview(overview))
    );
  }

  getParkingPricesOverview(parkingId: string): Observable<ParkingPricesOverview> {
    return this.httpClient.get<ParkingPricesOverview>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/prices`).pipe(
      map(overview => ParkingService._mapParkingPricesOverview(overview))
    );
  }

  getParkingDetails(parkingId: string, start: string, end: string): Observable<ParkingDetails> {
    return combineLatest([
      this.getParkingSummary(parkingId, start, end),
      this.getParkingTickets(parkingId, start, end),
      this.getParkingPensions(parkingId, start, end)
    ]).pipe(map(([summary, tickets, pensions]) => ({ summary, tickets, pensions })));
  }

  getParkingSummary(parkingId: string, start: string, end: string): Observable<ParkingSummary> {
    const params = new HttpParams()
      .append('from', start)
      .append('to', end)
    ;
    return this.httpClient.get<ParkingSummary>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/summary`, { params });
  }

  getParkingTickets(parkingId: string, start: string, end: string): Observable<TicketData[]> {
    const params = new HttpParams()
      .append('from', start)
      .append('to', end)
    ;
    return this.httpClient.get<TicketData[]>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/tickets`, { params }).pipe(
      map((data) => {
        for (let i = 0; i < data.length; i ++) {
          data[i] = ParkingService._mapDateTimeFields(data[i]);
          const total = parseFloat(data[i].total);
          if (!data[i].egress && !data[i].closingEmployee && total === 0) {
            data[i].total = null;
          }
        }
        return data;
      })
    );
  }

  getParkingPensions(parkingId: string, start: string, end: string): Observable<PensionData[]> {
    const params = new HttpParams()
      .append('from', start)
      .append('to', end)
    ;
    return this.httpClient.get<PensionData[]>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/pensions`, { params }).pipe(
      map((data) => {
        for (let i = 0; i < data.length; i ++) {
          data[i] = ParkingService._mapDateTimeFields(data[i]);
        }
        return data;
      })
    );
  }

  private static _mapDateTimeFields<T extends TicketData | PensionData>(data: T): T {
    const item = { ...data };
    const ingress = item.ingress ? moment(item.ingress, 'YYYY-MM-DD[T]HH:mm:ss.SSSSSSZZ') : undefined;
    const egress = item.egress ? moment(item.egress, 'YYYY-MM-DD[T]HH:mm:ss.SSSSSSZZ') : undefined;
    item.ingress = ingress ? ingress.format('DD/MM/YYYY[ - ]HH:mm:ss') : '';
    item.egress = egress ? egress.format('DD/MM/YYYY[ - ]HH:mm:ss') : '';
    item.diff = ingress && egress ? HelperService.generateTimeDiffString(ingress, egress) : '';
    return item;
  }

  private static _mapParkingOverview(overview: ParkingOverview): ParkingOverview {
    const promos = overview.tickets.promos;
    const validations = overview.tickets.validations;
    const hasValidator = (val: NameValueData | UniqueParkingValidationData): val is UniqueParkingValidationData =>
      (val as UniqueParkingValidationData).validator !== undefined;
    const sorter = (a: NameValueData | UniqueParkingValidationData, b: NameValueData | UniqueParkingValidationData): number => {
      if (hasValidator(a) && hasValidator(b) && a.validator !== b.validator) {
        return a.validator.localeCompare(b.validator, 'es');
      }
      return a.name.localeCompare(b.name, 'es');
    };
    overview.tickets.promos = promos.sort(sorter);
    overview.tickets.manual.shortPercentage = ParkingService._generateShortPercentage(overview.tickets.manual.percentage);
    overview.tickets.lost.shortPercentage = ParkingService._generateShortPercentage(overview.tickets.lost.percentage);
    overview.tickets.canceled.shortPercentage = ParkingService._generateShortPercentage(overview.tickets.canceled.percentage);
    if (validations.repeatable) {
      overview.tickets.validations.repeatable = validations.repeatable.sort(sorter);
    }
    return overview;
  }

  private static _generateShortPercentage(percent: string): string {
    const parsed = parseFloat(percent);
    if (isNaN(parsed)) {
      return percent;
    }
    return Math.round(parsed).toString();
  }

  private static _mapParkingPricesOverview(overview: ParkingPricesOverview): ParkingPricesOverview {
    const sorter: (a: NameValueData, b: NameValueData) => number = (a, b) => a.name.localeCompare(b.name, 'es');
    if (overview.promotions) {
      const promotions = overview.promotions;
      overview.promotions = promotions.sort(sorter);
    }
    if (overview.validations && overview.validations.repeatable) {
      const validations = overview.validations;
      overview.validations.repeatable = validations.repeatable.sort(sorter);
    }
    return overview;
  }
}
