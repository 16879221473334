import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { selectSessionState } from '@core/session/session.selectors';
import { Subject } from 'rxjs';

@Component({
  selector: 'yw-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnDestroy {
  private _onDestroy$: Subject<void>;
  userName: string;
  changePassForm: FormGroup;
  isLoading: boolean;
  @Output() changePasswordAttempt = new EventEmitter<[string, string]>();

  constructor(
    private store: Store<any>,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private formBuilder: FormBuilder
  ) {
    this.isLoading = false;
    this._onDestroy$ = new Subject<void>();
    this.changePassForm = this.formBuilder.group({
      currentPass: ['', Validators.required],
      newPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
      confirmPass: ['']
    });
    this.changePassForm.get('confirmPass')?.setValidators([
      Validators.required,
      ChangePasswordDialogComponent.checkPasswordsValidator(this.changePassForm.get('newPass'))
    ]);
    this.store.pipe(takeUntil(this._onDestroy$), select(selectSessionState)).subscribe((data) => {
      this.userName = data.user;
    });
  }

  attemptToChangePassword(): void {
    const previous = this.changePassForm.get('currentPass')?.value;
    const password = this.changePassForm.get('newPass')?.value;
    if (this.changePassForm.valid && !!previous && !!password) {
      this.changePasswordAttempt.emit([previous, password]);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }

  private static checkPasswordsValidator(originalControl: AbstractControl): ValidatorFn {
    return (confirmControl: AbstractControl): ValidationErrors | null => {
      const pass = originalControl?.value ?? '';
      const confirmPass = confirmControl?.value ?? '';
      return pass === confirmPass && !!confirmPass ? null : { confirmNotEqual: true };
    };
  }

}
