import { createAction, props } from '@ngrx/store';
import { ParkingDetails, ParkingFullOverview, CompanyData } from '@core/parking/parking.interfaces';
import { AppError } from '@shared/interfaces/app.interfaces';

export const getParkingsInformation = createAction(
  '[Parking] Get parkings information'
);
export const getParkingsInformationSuccess = createAction(
  '[Parking] Get parkings information success',
  props<{ parkingsData: CompanyData }>()
);
export const getParkingsInformationFail = createAction(
  '[Parking] Get parkings information fail',
  props<{ error: AppError }>()
);

export const getParkingOverview = createAction(
  '[Parking] Get parking overview',
  props<{ id: string, dateRanges?: { start: string, end: string } }>()
);
export const getParkingOverviewSuccess = createAction(
  '[Parking] Get parking overview success',
  props<{ id: string, parkingOverview: ParkingFullOverview }>()
);
export const getParkingOverviewFail = createAction(
  '[Parking] Get parking overview fail',
  props<{ id: string, error: AppError }>()
);

export const getParkingDetails = createAction(
  '[Parking] Get parking details',
  props<{ id: string, start: string, end: string }>()
);
export const getParkingDetailsSuccess = createAction(
  '[Parking] Get parking details success',
  props<{ id: string, parkingDetails: Partial<ParkingDetails> }>()
);
export const getParkingDetailsFail = createAction(
  '[Parking] Get parking details fail',
  props<{ id: string, error: AppError }>()
);
