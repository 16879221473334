import { createAction, props } from '@ngrx/store';
import { FilterDataRequested, MonthlyFilterData } from '@core/statistics/interfaces/statistics.interfaces';
import { ParkingIncomesByMonthValue } from './interfaces/get-incomes-by-month.interfaces';
import { ParkingAverageOccupancyData } from './interfaces/get-average-occupancy.interfaces';
import { ParkingPercentOccupancyData } from '@core/statistics/interfaces/get-percent-occupancy.interfaces';
import { ParkingEmployeeOperationsData } from '@core/statistics/interfaces/get-employee-operations.interfaces';
import { OperationsByStayData } from '@core/statistics/interfaces/get-operations-by-stay.interfaces';
import { AppError } from '@shared/interfaces/app.interfaces';
import { AtLeastOne } from '@shared/interfaces/utils.interfaces';
import { FrequentCustomersData } from '@core/statistics/interfaces/get-frequent-customers.interfaces';

export const changeStatisticsParkingId = createAction(
  '[Statistics] Change statistics parking id',
  props<{ parkingId: string }>()
);

export const retrieveIncomePerDayOfMonthData = createAction(
  '[Statistics] Retrieve income per day of month data',
  props<{ filter: MonthlyFilterData }>()
);
export const removeIncomePerDayOfMonthData = createAction(
  '[Statistics] Remove income per day of month data',
  props<{ filter: MonthlyFilterData }>()
);
export const setActiveIncomePerDayOfMonthData = createAction(
  '[Statistics] Set active income per day of month data',
  props<{ activeMonthYearId: string }>()
);
export const retrieveIncomePerDayOfMonthDataSuccess = createAction(
  '[Statistics] Retrieve income per day of month data success',
  props<{ data: ParkingIncomesByMonthValue[], filter: MonthlyFilterData }>()
);
export const retrieveIncomePerDayOfMonthDataFail = createAction(
  '[Statistics] Retrieve income per day of month data fail',
  props<{ error: AppError }>()
);

export const retrieveAverageOccupancyData = createAction(
  '[Statistics] Retrieve average occupancy data',
  props<{ filter: FilterDataRequested }>()
);
export const retrieveAverageOccupancyDataSuccess = createAction(
  '[Statistics] Retrieve average occupancy data success',
  props<{ data: ParkingAverageOccupancyData, filter: FilterDataRequested }>()
);
export const retrieveAverageOccupancyDataFail = createAction(
  '[Statistics] Retrieve average occupancy data fail',
  props<{ error: AppError }>()
);

export const retrievePercentOccupancyData = createAction(
  '[Statistics] Retrieve percent occupancy data',
  props<{ filter: FilterDataRequested }>()
);
export const retrievePercentOccupancyDataSuccess = createAction(
  '[Statistics] Retrieve percent occupancy data success',
  props<{ data: ParkingPercentOccupancyData, filter: FilterDataRequested }>()
);
export const retrievePercentOccupancyDataFail = createAction(
  '[Statistics] Retrieve percent occupancy data fail',
  props<{ error: AppError }>()
);

export const retrieveEmployeeOperationsData = createAction(
  '[Statistics] Retrieve employee operations data',
  props<{ filter: FilterDataRequested }>()
);
export const retrieveEmployeeOperationsDataSuccess = createAction(
  '[Statistics] Retrieve employee operations data success',
  props<{ data: ParkingEmployeeOperationsData, filter: FilterDataRequested }>()
);
export const retrieveEmployeeOperationsDataFail = createAction(
  '[Statistics] Retrieve employee operations data fail',
  props<{ error: AppError }>()
);

export const retrieveOperationsByStayData = createAction(
  '[Statistics] Retrieve operations by stay data',
  props<{ filter: FilterDataRequested }>()
);
export const retrieveOperationsByStayDataSuccess = createAction(
  '[Statistics] Retrieve operations by stay data success',
  props<{ data: OperationsByStayData, filter: FilterDataRequested }>()
);
export const retrieveOperationsByStayDataFail = createAction(
  '[Statistics] Retrieve operations by stay data fail',
  props<{ error: AppError }>()
);

export const retrieveFrequentCustomersData = createAction(
  '[Statistics] Retrieve frequent customers data',
  props<AtLeastOne<{ filter: FilterDataRequested, minimumFrequency: number | string }>>()
);
export const retrieveFrequentCustomersDataSuccess = createAction(
  '[Statistics] Retrieve frequent customers data success',
  props<{ data: FrequentCustomersData, filter: FilterDataRequested, minimumFrequency: number | string }>()
);
export const retrieveFrequentCustomersDataFail = createAction(
  '[Statistics] Retrieve frequent customers data fail',
  props<{ error: AppError }>()
);
