import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { SessionState } from './session.interfaces';
import {
  changeLayoutOptions,
  loginAttempt,
  loginAttemptFail,
  loginAttemptSuccess, logoutAttempt, logoutAttemptFail,
  logoutAttemptSuccess, setManualLoadingState, setUserName,
  setModulesAllowed
} from './session.actions';
import { initialStatisticsState } from '@core/statistics/statistics.reducer';
import { initialParkingState } from '@core/parking/parking.reducer';

const initialSessionState: SessionState = {
  sessionToken: undefined,
  user: '',
  modulesAllowed: [],
  layoutOptions: {
    collapsedOverview: true
  },
  loading: false
};

const _authenticationReducer = createReducer(
  initialSessionState,
  on(loginAttempt, logoutAttempt, (oldState: SessionState): SessionState => {
    return { ...oldState, loading: true, error: undefined };
  }),
  on(loginAttemptSuccess, (oldState: SessionState, { accessToken }): SessionState => {
    return { ...oldState, sessionToken: accessToken, loading: false };
  }),
  on(loginAttemptFail, logoutAttemptFail, (oldState: SessionState, { error }): SessionState => {
    return { ...oldState, error, loading: false };
  }),
  on(setUserName, (oldState: SessionState, { user }): SessionState => {
    return { ...oldState, user };
  }),
  on(setModulesAllowed, (oldState: SessionState, { modulesAllowed }): SessionState => {
    return { ...oldState, modulesAllowed };
  }),
  on(setManualLoadingState, (oldState: SessionState, { loading }): SessionState => {
    return { ...oldState, loading };
  }),
  on(changeLayoutOptions, (oldState: SessionState, { options }): SessionState => {
    return { ...oldState, layoutOptions: { ...oldState.layoutOptions, ...options } };
  })
);

export function clearStateOnLogoutReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    let newState = state;
    if (action.type === logoutAttemptSuccess.type) {
      newState = {
        session: initialSessionState,
        parkings: initialParkingState,
        statistics: initialStatisticsState
      };
    }
    return reducer(newState, action);
  };
}

export function authenticationReducer(state: SessionState, action: Action): SessionState {
  return _authenticationReducer(state, action);
}
