<div class="modal fade" aria-hidden="true" #modal>
  <div class="modal-dialog modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Escaneos del folio "{{ folio }}" con placa "{{ plates }}".
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center align-items-center g-0">
          <div class="col-lg-12 mb-2">
            <span class="badge bg-info">{{ data?.summary.total }} escaneos</span>
          </div>
          <div class="col-lg-4" *ngIf="data?.summary.scanner">
            <span class="badge bg-secondary">{{ data?.summary.scanner }} por escáner</span>
          </div>
          <div class="col-lg-4" *ngIf="data?.summary.plates">
            <span class="badge bg-secondary">{{ data?.summary.plates }} por placa</span>
          </div>
          <div class="col-lg-4" *ngIf="data?.summary.unknown">
            <span class="badge bg-secondary">{{ data?.summary.unknown }} fuente desconocida</span>
          </div>
        </div>
        <table class="table table-striped table-bordered mt-4">
          <thead>
            <tr>
              <th scope="col">Método</th>
              <th scope="col">Fecha</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let scan of data.scans">
              <th>{{ getScanMethod(scan.method) }}</th>
              <td>{{ getScanDate(scan.scannedAt) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
