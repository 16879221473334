import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CustomIconsService {

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  loadIcons(): void {
    this.matIconRegistry.addSvgIcon('percent', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/percent-solid.svg'));
    this.matIconRegistry.addSvgIcon('excel', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/file-excel-solid.svg'));
  }
}
