import { createAction, props } from '@ngrx/store';
import { AppError } from '@shared/interfaces/app.interfaces';
import { LayoutOptions } from '@core/session/session.interfaces';

export const loginAttempt = createAction(
  '[Session] Login attempt',
  props<{ user: string, pass: string }>()
);
export const loginAttemptSuccess = createAction(
  '[Session] Login attempt success',
  props<{ accessToken: string, withRedirect?: boolean }>()
);
export const loginAttemptFail = createAction(
  '[Session] Login attempt failure',
  props<{ error: AppError }>()
);

export const setUserName = createAction(
  '[Session] Set user name',
  props<{ user: string }>()
);
export const setModulesAllowed = createAction(
  '[Session] Set modules allowed',
  props<{ modulesAllowed: string[] }>()
);
export const setManualLoadingState = createAction(
  '[Session] Set manual loading state',
  props<{ loading: boolean }>()
);
export const showSimpleToast = createAction(
  '[Session] Show toast',
  props<{ text: string; color?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' }>()
);

export const changeLayoutOptions = createAction(
  '[Session] Change layout options',
  props<{ options: Partial<LayoutOptions> }>()
);
export const openChangePasswordDialog = createAction(
  '[Session] Open change password dialog'
);

export const logoutAttempt = createAction(
  '[Session] Logout attempt'
);
export const logoutAttemptSuccess = createAction(
  '[Session] Logout attempt success'
);
export const logoutAttemptFail = createAction(
  '[Session] Logout attempt failure',
  props<{ error: AppError }>()
);
