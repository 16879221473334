import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ParkingState } from '@core/parking/parking.interfaces';

export  const getParkingsState = createFeatureSelector<ParkingState>('parkings');

export const getParkingsLoadingState = createSelector(getParkingsState, state => state.loading);
export const getParkingsErrorState = createSelector(getParkingsState, state => state.error);
export const getCompanyData = createSelector(getParkingsState, state => state.companyData);
export const getLastUpdated = createSelector(getParkingsState, (state) => state.lastUpdated);
export const getParkingsData = createSelector(getParkingsState, (state) => {
  return state.parkings ? state.parkings : {};
});

export const getParkingPensionsEnabled = (id: string) => createSelector(getCompanyData, (state) => {
  return !!state?.parkings.find(p => p.id === id)?.hasPensions;
});
export const gerPakingIdDetailsRoute = (id: string) => createSelector(getCompanyData, (state) => {
  const parkings = state?.parkings;
  if (parkings) {
    const parkingIndex = parkings.findIndex(p => p.id === id);
    if (parkingIndex >= 0) {
      return `${ parkingIndex + 1 } ${parkings[parkingIndex].name}/details`;
    }
  }
  return '';
});
export const searchParkingIdByRoute = (routeId: string) => createSelector(getCompanyData, (state): string | undefined => {
  const parkingNameToSearch = (routeId || '').split(' ');
  const index = parseInt(parkingNameToSearch.shift(), 10) - 1;
  const parking = state?.parkings.find((p, i) => p.name === parkingNameToSearch.join(' ') && i === index);
  return parking?.id;
});
