import { AppError } from '@shared/interfaces/app.interfaces';
import { ParkingIncomesByMonthGroup } from '@core/statistics/interfaces/get-incomes-by-month.interfaces';
import { OperationsByStayData } from '@core/statistics/interfaces/get-operations-by-stay.interfaces';
import { ParkingAverageOccupancyData } from '@core/statistics/interfaces/get-average-occupancy.interfaces';
import { ParkingPercentOccupancyData } from '@core/statistics/interfaces/get-percent-occupancy.interfaces';
import { ParkingEmployeeOperationsData } from '@core/statistics/interfaces/get-employee-operations.interfaces';
import { FrequentCustomersData } from '@core/statistics/interfaces/get-frequent-customers.interfaces';

export const MONTH_YEAR_FORMAT = 'YYYY-MM';
export const monthlyFilterDataToMonthYearId = (filter: MonthlyFilterData): string => `${ filter.year }-${ filter.month }`;

export type FilterDataRequested = 'total' | YearlyFilterData | MonthlyFilterData | DailyFilterData;

export interface StatisticsState {
  activeParkingId?: string;
  incomePerDayOfMonth: {
    activeFilters: MonthlyFilterData[];
    activeMonthYearId?: string;
    data?: ParkingIncomesByMonthGroup;
  };
  operationsByStay: {
    activeFilter: FilterDataRequested;
    data?: OperationsByStayData;
  };
  averageOccupancy: {
    activeFilter: FilterDataRequested;
    data?: ParkingAverageOccupancyData;
  };
  percentOccupancy: {
    activeFilter: FilterDataRequested;
    data?: ParkingPercentOccupancyData;
  };
  employeeOperations: {
    activeFilter: FilterDataRequested;
    data?: ParkingEmployeeOperationsData;
  };
  frequentClient: {
    activeFilter: FilterDataRequested;
    activeMinimumFrequencyCriteria: number | string;
    data?: FrequentCustomersData;
  };
  loading: boolean;
  error?: AppError;
}

export interface YearlyFilterData {
  type: 'year';
  year: string;
}

export interface MonthlyFilterData {
  type: 'month';
  month: string;
  year: string;
}

export interface DailyFilterData {
  type: 'days';
  from: string;
  to: string;
}

export interface ChartMarginValues {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface ChartDimensionValues {
  margin: ChartMarginValues;
  width: number;
  height: number;
}
