import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { getSessionToken } from '@core/session/session.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private store: Store<any>, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.pipe(
      take(1),
      select(getSessionToken),
      map(token => !!token ? true : this.router.parseUrl('/login'))
    );
  }

}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuardInverse implements CanActivate {

  constructor(private store: Store<any>, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.pipe(
      take(1),
      select(getSessionToken),
      map(token => !token ? true : this.router.parseUrl('/dashboard'))
    );
  }

}
