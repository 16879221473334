import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getSessionToken } from '@core/session/session.selectors';
import { concatMap, first, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {
  private readonly msForTimeout = 15000;

  constructor(private store: Store<any>) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.store.pipe(
      select(getSessionToken),
      first(),
      concatMap((token: string) => {
        let req = request;
        if (token) {
          req = request.clone({
            setHeaders: { Authorization: `Bearer ${ token }` }
          });
        }
        return next.handle(req).pipe(timeout(this.msForTimeout));
      })
    );
  }
}
