import { monthlyFilterDataToMonthYearId, StatisticsState } from '@core/statistics/interfaces/statistics.interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import {
  changeStatisticsParkingId,
  removeIncomePerDayOfMonthData,
  retrieveAverageOccupancyData,
  retrieveAverageOccupancyDataFail,
  retrieveAverageOccupancyDataSuccess,
  retrieveEmployeeOperationsData,
  retrieveEmployeeOperationsDataFail,
  retrieveEmployeeOperationsDataSuccess,
  retrieveFrequentCustomersData,
  retrieveFrequentCustomersDataFail,
  retrieveFrequentCustomersDataSuccess,
  retrieveIncomePerDayOfMonthData,
  retrieveIncomePerDayOfMonthDataFail,
  retrieveIncomePerDayOfMonthDataSuccess,
  retrieveOperationsByStayData,
  retrieveOperationsByStayDataFail,
  retrieveOperationsByStayDataSuccess,
  retrievePercentOccupancyData,
  retrievePercentOccupancyDataFail,
  retrievePercentOccupancyDataSuccess,
  setActiveIncomePerDayOfMonthData
} from '@core/statistics/statistics.actions';
import { cloneDeep as _cloneDeep } from 'lodash';

export const initialStatisticsState: StatisticsState = {
  incomePerDayOfMonth: {
    activeFilters: []
  },
  operationsByStay: {
    activeFilter: 'total'
  },
  averageOccupancy: {
    activeFilter: 'total'
  },
  percentOccupancy: {
    activeFilter: 'total'
  },
  employeeOperations: {
    activeFilter: 'total'
  },
  frequentClient: {
    activeFilter: 'total',
    activeMinimumFrequencyCriteria: '10'
  },
  loading: false
};

const _statisticsReducer = createReducer(
  initialStatisticsState,
  on(changeStatisticsParkingId, (state: StatisticsState, { parkingId }): StatisticsState => {
    return { ...state, activeParkingId: parkingId };
  }),
  on(retrieveIncomePerDayOfMonthData, retrieveAverageOccupancyData, retrievePercentOccupancyData, retrieveEmployeeOperationsData,
    retrieveOperationsByStayData, retrieveFrequentCustomersData, (state: StatisticsState): StatisticsState => {
      return { ...state, loading: true, error: undefined };
    }
  ),
  on(removeIncomePerDayOfMonthData, (state: StatisticsState, { filter }): StatisticsState => {
    const incomePerDayOfMonth = _cloneDeep(state.incomePerDayOfMonth);
    const monthYearId = monthlyFilterDataToMonthYearId(filter);
    delete incomePerDayOfMonth.data[monthYearId];
    incomePerDayOfMonth.activeFilters = incomePerDayOfMonth.activeFilters.filter(f => !(f.month === filter.month && f.year === f.year));
    if (incomePerDayOfMonth.activeMonthYearId === monthYearId) {
      incomePerDayOfMonth.activeMonthYearId = incomePerDayOfMonth.activeFilters.length > 0
        ? monthlyFilterDataToMonthYearId(incomePerDayOfMonth.activeFilters[0])
        : undefined;
    }
    return { ...state, incomePerDayOfMonth };
  }),
  on(retrieveIncomePerDayOfMonthDataSuccess, (state: StatisticsState, { data, filter }): StatisticsState => {
    const incomePerDayOfMonth = _cloneDeep(state.incomePerDayOfMonth);
    incomePerDayOfMonth.data = { ...incomePerDayOfMonth.data, [monthlyFilterDataToMonthYearId(filter)]: data };
    incomePerDayOfMonth.activeFilters.push(filter);
    return { ...state, loading: false, incomePerDayOfMonth };
  }),
  on(setActiveIncomePerDayOfMonthData, (state: StatisticsState, { activeMonthYearId }): StatisticsState => {
    const incomePerDayOfMonth = _cloneDeep(state.incomePerDayOfMonth);
    incomePerDayOfMonth.activeMonthYearId = activeMonthYearId;
    return { ...state, incomePerDayOfMonth };
  }),
  on(retrieveAverageOccupancyDataSuccess, (state: StatisticsState, { data, filter }): StatisticsState => {
    return { ...state, loading: false, averageOccupancy: { data, activeFilter: filter } };
  }),
  on(retrievePercentOccupancyDataSuccess, (state: StatisticsState, { data, filter }): StatisticsState => {
    return { ...state, loading: false, percentOccupancy: { data, activeFilter: filter } };
  }),
  on(retrieveEmployeeOperationsDataSuccess, (state: StatisticsState, { data, filter }): StatisticsState => {
    return { ...state, loading: false, employeeOperations: { data, activeFilter: filter } };
  }),
  on(retrieveOperationsByStayDataSuccess, (state: StatisticsState, { data, filter }): StatisticsState => {
    return { ...state, loading: false, operationsByStay: { data, activeFilter: filter } };
  }),
  on(retrieveFrequentCustomersDataSuccess, (state: StatisticsState, { data, filter, minimumFrequency }): StatisticsState => {
    return { ...state, loading: false, frequentClient: { data, activeMinimumFrequencyCriteria: minimumFrequency, activeFilter: filter } };
  }),
  on(retrieveIncomePerDayOfMonthDataFail, retrieveAverageOccupancyDataFail, retrievePercentOccupancyDataFail,
    retrieveEmployeeOperationsDataFail, retrieveOperationsByStayDataFail, retrieveFrequentCustomersDataFail,
    (state: StatisticsState, { error }): StatisticsState => {
      return { ...state, error, loading: false };
    }
  )
);

export function statisticsReducer(state: StatisticsState, action: Action): StatisticsState {
  return _statisticsReducer(state, action);
}
