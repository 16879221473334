import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StatisticsState } from '@core/statistics/interfaces/statistics.interfaces';

export const getStatisticsState = createFeatureSelector<StatisticsState>('statistics');

export const getStatisticsLoadingState = createSelector(getStatisticsState, state => state.loading);
export const getStatisticsErrorState = createSelector(getStatisticsState, state => state.error);
export const getActiveStatisticsParkingIdState = createSelector(getStatisticsState, state => state.activeParkingId);
export const getIncomePerDayOfMonthState = createSelector(getStatisticsState, state => state.incomePerDayOfMonth);
export const getAverageOccupancy = createSelector(getStatisticsState, state => state.averageOccupancy);
export const getOperationsByStay = createSelector(getStatisticsState, state => state.operationsByStay);
export const getPercentOccupancy = createSelector(getStatisticsState, state => state.percentOccupancy);
export const getEmployeeOperations = createSelector(getStatisticsState, state => state.employeeOperations);
export const getFrequentClient = createSelector(getStatisticsState, state => state.frequentClient);
export const getFrequentClientCustomerOps = createSelector(getFrequentClient, state => state.data?.customerOps);
export const getFrequentClientRecurrenceSummary = createSelector(getFrequentClient, state => state.data?.recurrenceSummary);
