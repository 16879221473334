import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { getSessionToken } from '@core/session/session.selectors';
import { logoutAttempt } from '@core/session/session.actions';
import { Observable, timer } from 'rxjs';
import { JSEncrypt } from 'jsencrypt';
import { environment } from '@environments/environment';
import { map, take } from 'rxjs/operators';
import { AppError, TimeoutError } from '@shared/interfaces/app.interfaces';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private token: string | undefined;
  private publicKey: string | undefined;

  constructor(private httpClient: HttpClient, private store: Store<any>) {
    this.store.pipe(select(getSessionToken)).subscribe((token) => this.token = token);
  }

  generateUnAllowedError(): AppError {
    return this.generateErrorFromMessage('No se tiene permiso para traer la información solicitada');
  }

  generateErrorFromMessage(message: string): AppError {
    return {
        message,
        name: 'Enforced error message',
        statusText: 'Enforced error message',
        url: ''
      };
  }

  parseHttpError(error: HttpErrorResponse | TimeoutError): AppError {
    if (error.name === 'TimeoutError') {
      return {
        message: 'El tiempo de espera expiró, verifica tu conexión a internet e intenta nuevamente',
        name: error.name,
        statusText: error.name,
        url: ''
      };
    }
    if (error.status === 401 && this.token) {
      this.store.dispatch(logoutAttempt());
      return { message: 'Sesión expirada, inicie sesión nuevamente', name: error.name, statusText: error.statusText, url: error.url };
    }
    console.error(error);
    return {
      message: error.error && error.error.message ? error.error.message : 'Algo salió mal, contacta a tu administrador.',
      name: error.name,
      statusText: error.statusText,
      url: error.url
    };
  }

  encryptData(data: { [key: string]: string }): Observable<{ [key: string]: string }> {
    if (this.publicKey) {
      return timer(50).pipe(take(1), map(() => this.runEncryption(data)));
    }
    return this.getPublicKey().pipe(map((key) => {
      this.publicKey = key;
      return this.runEncryption(data);
    }));
  }

  private runEncryption(data: { [key: string]: string }): { [key: string]: string } {
    const returnObj = {};
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(this.publicKey);
    for (const key of Object.keys(data)) {
      returnObj[key] = (encrypt.encrypt(data[key]) as string).trim();
    }
    return returnObj;
  }

  private getPublicKey(): Observable<string> {
    return this.httpClient.get(`${environment.apiUrl}/auth/jwt.pub`, { responseType: 'text' }).pipe(
      map((key) => key.trim())
    );
  }

  static getNowDateTime(): string {
    return moment().format('YYYY-MM-DD[T]HH:mm:ss.SSSSSSZZ');
  }

  static generateTimeDiffString(ingress: string | moment.Moment, egress: string | moment.Moment): string {
    if (ingress && egress) {
      const entrance: moment.Moment = typeof ingress === 'string' ? moment(ingress, 'YYYY-MM-DD[T]HH:mm:ss.SSSSSSZZ') : ingress;
      const exit: moment.Moment = typeof egress === 'string' ? moment(egress, 'YYYY-MM-DD[T]HH:mm:ss.SSSSSSZZ') : egress;
      const diff = moment.duration(moment(exit).diff(moment(entrance)));
      const days = parseInt(diff.asDays().toString(), 10);
      const hours = parseInt(diff.asHours().toString(), 10) - days * 24;
      const minutes = parseInt(diff.asMinutes().toString(), 10) - (days * 24 * 60 + hours * 60);
      let diffString = `${ minutes } min`;
      if (hours > 0) {
        diffString = `${ hours } hrs ${ diffString }`;
      }
      if (days > 0) {
        diffString = `${ days } días ${ diffString }`;
      }
      return diffString;
    }
    return '';
  }

  static orderArrayLikeElements<T extends { order: number }>(array: T[]): T[] {
    return array.sort((a, b) => a.order > b.order ? 1 : -1);
  }

  static pickObjectKeys<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    const newObj = Object.assign({}, obj);
    const objKeys = (Object.keys(obj) as K[]).filter(k => !keys.includes(k));
    objKeys.forEach(key => delete newObj[key]);
    return newObj;
  }

  static removeSecondsFromTimestamp(timestamp: string): string {
    const splitted = timestamp.split(':');
    if (splitted.length === 3) {
      splitted.pop();
    }
    return splitted.join(':');
  }

}
