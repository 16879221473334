import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '@shared/services/helper.service';
import { Observable, timer } from 'rxjs';
import { concatMap, take, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private httpClient: HttpClient, private helperService: HelperService) {}

  login(id: string, pass: string): Observable<{ accessToken: string }> {
    return this.helperService.encryptData({ username: id, password: pass }).pipe(
      concatMap((encryptedData) => {
        return this.httpClient.post<{ accessToken: string }>(`${environment.apiUrl}/auth/company/login`, encryptedData).pipe(
          tap((data) => localStorage.setItem('accessToken', data.accessToken))
        );
      })
    );
  }

  changePassword(previous: string, password: string): Observable<void> {
    return this.helperService.encryptData({ previous, password }).pipe(
      concatMap((encryptedData) => {
        return this.httpClient.put<void>(`${environment.apiUrl}/auth/password`, encryptedData).pipe();
      })
    );
  }

  parseToken(token: string): any {
    return jwt_decode(token);
  }

  logout(): Observable<any> {
    localStorage.removeItem('accessToken');
    return timer(250).pipe(take(1));
  }
}
