import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from '@core/session/session.interfaces';

export const selectSessionState = createFeatureSelector<SessionState>('session');

export const getSessionLoadingState = createSelector(selectSessionState, state => state.loading);
export const getSessionErrorState = createSelector(selectSessionState, state => state.error);
export const getSessionToken = createSelector(selectSessionState, state => state.sessionToken);
export const getSessionLayoutOption = createSelector(selectSessionState, state => state.layoutOptions);

export const selectAllModulesFromSessionAllowed = createSelector(selectSessionState, state => state.modulesAllowed);
export const selectModuleFromSessionAllowed = (moduleName: string) => createSelector(
  selectAllModulesFromSessionAllowed,
  (modulesAllowed) => modulesAllowed.includes('*/*') || modulesAllowed.includes(moduleName)
);
