import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  FilterDataRequested, MONTH_YEAR_FORMAT,
  MonthlyFilterData, monthlyFilterDataToMonthYearId,
} from '@core/statistics/interfaces/statistics.interfaces';
import { ParkingIncomesByMonthValue } from './interfaces/get-incomes-by-month.interfaces';
import { ParkingAverageOccupancyData } from '@core/statistics/interfaces/get-average-occupancy.interfaces';
import { ParkingPercentOccupancyData } from '@core/statistics/interfaces/get-percent-occupancy.interfaces';
import { ParkingEmployeeOperationsData } from '@core/statistics/interfaces/get-employee-operations.interfaces';
import { OperationsByStayData } from '@core/statistics/interfaces/get-operations-by-stay.interfaces';
import { FrequentCustomersData } from '@core/statistics/interfaces/get-frequent-customers.interfaces';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { map, delay } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { HelperService } from '@shared/services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  private readonly placeholderParkingIds = [
    'c88c3924-a475-42d2-aeeb-a4982927e660',
    'bf77c830-9dce-43d7-9ad7-58d23a93eca2'
  ];
  private readonly mockRequestTimeMs = 150;
  private monthCounter = 1;

  constructor(private httpClient: HttpClient) {}

  getIncomesByMonth(parkingId: string, filter: MonthlyFilterData): Observable<ParkingIncomesByMonthValue[]> {
    if (this.placeholderParkingIds.includes(parkingId)) {
      if (this.monthCounter === 1) {
        this.monthCounter += 1;
        return this.httpClient.get<ParkingIncomesByMonthValue[]>('/statistics.mocks/incomes-by-month-data.july.json').pipe(
          delay(this.mockRequestTimeMs)
        );
      }
      return this.httpClient.get<ParkingIncomesByMonthValue[]>('/statistics.mocks/incomes-by-month-data.june.json').pipe(
        delay(this.mockRequestTimeMs)
      );
    }
    const momentDate = moment();
    momentDate.set({ month: parseInt(filter.month, 10) - 1, year: parseInt(filter.year, 10) });
    const params = new HttpParams().appendAll({
      type: 'incomes-by-month',
      from: `${ momentDate.format('YYYY-MM') }-01`
    });
    return this.httpClient.get<ParkingIncomesByMonthValue[]>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/stats`, { params });
  }

  getAverageOccupancy(parkingId: string, filter: FilterDataRequested): Observable<ParkingAverageOccupancyData> {
    if (this.placeholderParkingIds.includes(parkingId)) {
      return this.httpClient.get<ParkingAverageOccupancyData>('/statistics.mocks/average-occupancy-data.json').pipe(
        delay(this.mockRequestTimeMs),
        map(StatisticsService._generateShortLabels)
      );
    }
    const params = StatisticsService.generateParamsFromFilter(filter).appendAll({ type: 'average-occupancy' });
    return this.httpClient.get<ParkingAverageOccupancyData>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/stats`, { params }).pipe(
      map(StatisticsService._ensureOrderingForAverageOccupancyData),
      map(StatisticsService._generateShortLabels)
    );
  }

  getPercentOccupancy(parkingId: string, filter: FilterDataRequested): Observable<ParkingPercentOccupancyData> {
    if (this.placeholderParkingIds.includes(parkingId)) {
      return this.httpClient.get<ParkingPercentOccupancyData>('/statistics.mocks/percent-occupancy-data.json').pipe(
        delay(this.mockRequestTimeMs),
        map(StatisticsService._generateShortLabels)
      );
    }
    const params = StatisticsService.generateParamsFromFilter(filter).appendAll({ type: 'percent-occupancy' });
    return this.httpClient.get<ParkingPercentOccupancyData>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/stats`, { params }).pipe(
      map(StatisticsService._generateShortLabels)
    );
  }

  getEmployeeOperations(parkingId: string, filter: FilterDataRequested): Observable<ParkingEmployeeOperationsData> {
    if (this.placeholderParkingIds.includes(parkingId)) {
      return this.httpClient.get<ParkingEmployeeOperationsData>('/statistics.mocks/employee-operations-data.json').pipe(
        delay(this.mockRequestTimeMs)
      );
    }
    const params = StatisticsService.generateParamsFromFilter(filter).appendAll({ type: 'employee-operations' });
    return this.httpClient.get<ParkingEmployeeOperationsData>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/stats`, { params });
  }

  getOperationsByStay(parkingId: string, filter: FilterDataRequested): Observable<OperationsByStayData> {
    const params = StatisticsService.generateParamsFromFilter(filter).appendAll({ type: 'operations-by-stay' });
    return this.httpClient.get<OperationsByStayData>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/stats`, { params });
  }

  getFrequentCustomers(
    parkingId: string,
    filter: FilterDataRequested,
    minFrequencyCriteria: number | string
  ): Observable<FrequentCustomersData> {
    let params = StatisticsService.generateParamsFromFilter(filter).appendAll({ type: 'frequent-customers' });
    params = params.append('freq', minFrequencyCriteria);
    return this.httpClient.get<FrequentCustomersData>(`${ environment.apiUrl }/parkings/${ parkingId }/admin/stats`, { params }).pipe(
      map((data: FrequentCustomersData): FrequentCustomersData => {
        return {
          ...data,
          customerOps: data.customerOps || [],
          recurrenceSummary: data.recurrenceSummary || []
        };
      })
    );
  }

  public static parseFilterToReadableString(filter: FilterDataRequested): string {
    if (!filter) {
      return '';
    }
    if (filter === 'total') {
      return 'Todo';
    }
    if (filter.type === 'year') {
      return `Año ${ filter.year }`;
    }
    if (filter.type === 'month') {
      const momentInstance = moment(monthlyFilterDataToMonthYearId(filter), MONTH_YEAR_FORMAT);
      return `Mes de ${ momentInstance.format('MMMM [de] YYYY') }`;
    }
    if (filter.type === 'days') {
      const from = moment(filter.from, 'YYYY-MM-DD');
      const to = moment(filter.to, 'YYYY-MM-DD');
      const daysDiff = to.diff(from, 'days') + 1;
      if (from.isSame(to, 'days')) {
        return `Día ${ from.format('DD [de] MMMM [de] YYYY') }`;
      }
      if (from.isSame(to, 'months')) {
        return `(${ daysDiff }) Días del ${ from.format('DD') } al ${ to.format('DD [de] MMMM [de] YYYY') }`;
      }
      if (from.isSame(to, 'years')) {
        return `(${ daysDiff }) Días del ${ from.format('DD [de] MMMM') } al ${ to.format('DD [de] MMMM [de] YYYY') }`;
      }
      return `(${ daysDiff }) Días del ${ from.format('DD [de] MMMM [de] YYYY') } al ${ to.format('DD [de] MMMM [de] YYYY') }`;
    }
    return '';
  }

  public static generateParamsFromFilter(filter: FilterDataRequested): HttpParams {
    const params = new HttpParams();
    if (filter === 'total') {
      return params.appendAll({ from: '2000-01-01', to: '3000-01-01' });
    }
    if (filter.type === 'year') {
      return params.appendAll({ from: `${ filter.year }-01-01`, to: `${ filter.year }-12-31` });
    }
    if (filter.type === 'month') {
      const momentDate = moment();
      momentDate.set({ month: parseInt(filter.month, 10) - 1, year: parseInt(filter.year, 10) });
      const days = momentDate.daysInMonth();
      return params.appendAll({ from: `${ momentDate.format('YYYY-MM') }-01`, to: `${ momentDate.format('YYYY-MM') }-${ days }` });
    }
    if (filter.type === 'days') {
      return params.appendAll({ from: filter.from, to: filter.to });
    }
    return params;
  }

  private static _ensureOrderingForAverageOccupancyData(data: ParkingAverageOccupancyData): ParkingAverageOccupancyData {
    const newData = cloneDeep(data);
    newData.main.map((d) => {
      d.shortLabel = d.label.split(' a ')[0];
      return d;
    });
    newData.main = HelperService.orderArrayLikeElements(newData.main);
    newData.secondary = HelperService.orderArrayLikeElements(newData.secondary).map((v) => {
      const newSecondaryData = cloneDeep(v);
      newSecondaryData.values = HelperService.orderArrayLikeElements(newSecondaryData.values).map((d) => {
        d.shortLabel = d.label.split(' a ')[0];
        return d;
      });
      return newSecondaryData;
    });
    return newData;
  }

  private static _generateShortLabels<T extends ParkingAverageOccupancyData | ParkingPercentOccupancyData>(data: T): T {
    data.main.map((d) => {
      d.shortLabel = d.label.split(' a ')[0];
      return d;
    });
    data.secondary.map((s) => {
      s.values.map((d) => {
        d.shortLabel = d.label.split(' a ')[0];
        return d;
      });
      return s;
    });
    return data;
  }
}
