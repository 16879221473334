import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import moment from 'moment';
import { select, Store } from '@ngrx/store';
import { loginAttemptSuccess, showSimpleToast } from '@core/session/session.actions';
import { combineLatest } from 'rxjs';
import { getSessionErrorState, getSessionLoadingState } from '@core/session/session.selectors';
import { getParkingsErrorState, getParkingsLoadingState } from '@core/parking/parking.selectors';
import { debounceTime, filter, map } from 'rxjs/operators';
import { AlertComponent } from '@components/alert/alert.component';
import { getStatisticsErrorState, getStatisticsLoadingState } from '@core/statistics/statistics.selectors';
import { CustomIconsService } from '@shared/services/custom-icons.service';
import { Actions, ofType } from '@ngrx/effects';
import { getReportsErrorState, getReportsLoadingState } from '@core/reports/reports.selectors';

@Component({
  selector: 'yw-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements AfterViewInit {
  @ViewChild('loading') loadingContainer: ElementRef<HTMLDivElement>;
  @ViewChild(AlertComponent) alertComponent: AlertComponent;
  showAlert = false;

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private customIconsService: CustomIconsService
  ) {
    moment.locale('es-MX');
    this.customIconsService.loadIcons();
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      this.store.dispatch(loginAttemptSuccess({ accessToken }));
    }
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.store.pipe(select(getSessionLoadingState)),
      this.store.pipe(select(getParkingsLoadingState)),
      this.store.pipe(select(getStatisticsLoadingState)),
      this.store.pipe(select(getReportsLoadingState))
    ]).pipe(
      debounceTime(100),
      map((loadings: boolean[]): boolean => {
        return loadings.reduce((accumulator, loading) => accumulator || loading, false);
      })
    ).subscribe((isLoading) => {
      this.loadingContainer.nativeElement.style.display = isLoading ? 'flex' : 'none';
    });

    combineLatest([
      this.store.pipe(select(getSessionErrorState)),
      this.store.pipe(select(getParkingsErrorState)),
      this.store.pipe(select(getStatisticsErrorState)),
      this.store.pipe(select(getReportsErrorState))
    ]).pipe(
      map((errors) => errors.find(err => err)),
      filter(err => !!err)
    ).subscribe((error) => {
      this.showAlert = true;
      this.alertComponent.errorText = error.message;
      this.alertComponent.alertType = 'danger';
      this.alertComponent.showToast();
    });

    this.actions.pipe(
      ofType(showSimpleToast)
    ).subscribe((action) => {
      this.showAlert = true;
      this.alertComponent.errorText = action.text;
      this.alertComponent.alertType = action.color;
      this.alertComponent.showToast();
    });
  }

  hideToast(): void {
    this.showAlert = false;
    this.alertComponent.alertType = undefined;
  }

}
