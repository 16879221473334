import { AfterViewInit, Component, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Toast } from 'bootstrap';

@Component({
  selector: 'yw-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements AfterViewInit {
  @ViewChild('toast') toastElement: ElementRef<HTMLDivElement>;
  toastInstance: Toast;
  @Input() errorText: string;
  @Input() alertType?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' = 'danger';
  @Output() hiddenAlert = new EventEmitter<void>();

  ngAfterViewInit(): void {
    this.toastInstance = new Toast(this.toastElement.nativeElement);
    this.toastElement.nativeElement.addEventListener('hidden.bs.toast', () => {
      this.hiddenAlert.emit();
    });
  }

  showToast(): void {
    this.toastInstance.show();
  }

}
