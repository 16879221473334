import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap';
import { PrintsDetailForTicket } from '@core/parking/parking.interfaces';
import * as moment from 'moment';

@Component({
  selector: 'yw-print-count-modal',
  templateUrl: './print-count-modal.component.html',
  styleUrls: ['./print-count-modal.component.scss']
})
export class PrintCountModalComponent implements AfterViewInit {
  @ViewChild('modal') modalElement: ElementRef<HTMLDivElement>;
  @Output() hiddenModal = new EventEmitter<void>();
  modalInstance: Modal;
  folio = '';
  plates = '';
  data?: PrintsDetailForTicket;

  ngAfterViewInit(): void {
    this.modalInstance = new Modal(this.modalElement.nativeElement);
    this.modalElement.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.hiddenModal.emit();
    });
  }

  getScanDate(date?: string): string {
    if (!date) {
      return '';
    }
    return moment(date, 'YYYY-MM-DD[T]HH:mm:ss.SSSSSSZZ').format('DD/MM/YYYY[ - ]HH:mm:ss');
  }

  showModal(): void {
    this.modalInstance.show();
  }
}
