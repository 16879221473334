import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SessionService } from './session.service';
import {
  loginAttempt,
  loginAttemptFail,
  loginAttemptSuccess,
  logoutAttempt,
  logoutAttemptFail,
  logoutAttemptSuccess, openChangePasswordDialog, setModulesAllowed, setUserName, showSimpleToast
} from '@core/session/session.actions';
import { catchError, concatMap, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { HelperService } from '@shared/services/helper.service';
import { Router } from '@angular/router';
import { getParkingsInformation } from '@core/parking/parking.actions';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '@components/change-password-dialog/change-password-dialog.component';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class SessionEffects {

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private sessionService: SessionService,
    private helperService: HelperService,
    private matDialog: MatDialog,
    private router: Router
  ) {}

  authenticationAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(loginAttempt),
    mergeMap((action) => {
      return this.sessionService.login(action.user, action.pass).pipe(
        concatMap((data) => {
          return from(this.router.navigateByUrl('/dashboard')).pipe(
            map(() => loginAttemptSuccess({ accessToken: data.accessToken, withRedirect: true }))
          );
        }),
        catchError(error => of(loginAttemptFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  logoutAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(logoutAttempt),
    mergeMap(() => {
      return this.sessionService.logout().pipe(
        map(() => {
          return logoutAttemptSuccess();
        }),
        catchError((error) => of(logoutAttemptFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  logoutAttemptSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(logoutAttemptSuccess),
      mergeMap(() => this.router.navigateByUrl('/login'))
    ),
    { dispatch: false }
  );

  loginAttemptSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(loginAttemptSuccess),
      tap((action) => action.withRedirect && this.router.navigateByUrl('/dashboard')),
      mergeMap((action) => {
        const jwtData = this.sessionService.parseToken(action.accessToken);
        return [
          getParkingsInformation(),
          setUserName({ user: jwtData.code }),
          setModulesAllowed({ modulesAllowed: jwtData.mods || [] })
        ];
      })
    )
  );

  openChangePasswordDialog$ = createEffect(
    () => this.actions$.pipe(
      ofType(openChangePasswordDialog),
      tap(() => {
        const dialogRef = this.matDialog.open(ChangePasswordDialogComponent, {
          width: '500px'
        });
        dialogRef.componentInstance.changePasswordAttempt.pipe(
          takeUntil(dialogRef.afterClosed()),
          concatMap(([previousPass, newPass]) => {
            dialogRef.componentInstance.isLoading = true;
            return this.sessionService.changePassword(previousPass, newPass).pipe(
              catchError((err) => {
                let message = err.error && err.error.message ? err.error.message : 'Algo salió mal, contacta a tu administrador.';
                if (message === 'Invalid password') {
                  message = 'La contraseña es incorrecta, intenta nuevamente';
                }
                this.store.dispatch(showSimpleToast({ text: message, color: 'danger' }));
                dialogRef.componentInstance.isLoading = false;
                return of(false);
              })
            );
          })
        ).subscribe((data) => {
          if (data === false) {
            return;
          }
          this.store.dispatch(showSimpleToast({ text: 'Contraseña cambiada correctamente', color: 'info' }));
          dialogRef.componentInstance.isLoading = false;
          dialogRef.close();
        });
      })
    ),
    { dispatch: false }
  );

}
