import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { ReportsService } from '@core/reports/reports.service';
import { HelperService } from '@shared/services/helper.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getActiveStatisticsParkingIdState, getIncomePerDayOfMonthState, getOperationsByStay } from '@core/statistics/statistics.selectors';
import {
  generateIncomesByMonthSpreadsheet,
  generateIncomesByMonthSpreadsheetFail,
  generateIncomesByMonthSpreadsheetSuccess,
  generateOperationsByStaySpreadsheet,
  generateOperationsByStaySpreadsheetFail,
  generateOperationsByStaySpreadsheetSuccess,
  generateParkingDetailsSpreadsheet,
  generateParkingDetailsSpreadsheetFail,
  generateParkingDetailsSpreadsheetSuccess
} from '@core/reports/reports.actions';
import { of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { StatisticsService } from '@core/statistics/statistics.service';
import * as fileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ReportsEffects {

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private reportsService: ReportsService,
    private helperService: HelperService
  ) {}

  generateOperationsByStaySpreadsheet$ = createEffect(() => this.actions$.pipe(
    ofType(generateOperationsByStaySpreadsheet),
    concatLatestFrom(() => [
      this.store.pipe(select(getActiveStatisticsParkingIdState)),
      this.store.pipe(select(getOperationsByStay))
    ]),
    mergeMap(([_, id, operationsByStayData]) => {
      const activeFilter = operationsByStayData.activeFilter;
      return this.reportsService.generateOperationsByStay(id, activeFilter).pipe(
        map((data) => {
          const params = StatisticsService.generateParamsFromFilter(activeFilter);
          const fromParam = params.get('from');
          const toParam = params.get('to');
          const fallbackName = `Operaciones-por-estancia_${ fromParam }-${ toParam }.xlsx`;
          this._downloadAttemptForSpreadsheet(data, fallbackName);
          return generateOperationsByStaySpreadsheetSuccess();
        }),
        catchError(error => of(generateOperationsByStaySpreadsheetFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  generateIncomesByMonthSpreadsheet$ = createEffect(() => this.actions$.pipe(
    ofType(generateIncomesByMonthSpreadsheet),
    concatLatestFrom(() => [
      this.store.pipe(select(getActiveStatisticsParkingIdState)),
      this.store.pipe(select(getIncomePerDayOfMonthState))
    ]),
    mergeMap(([_, id, incompePerDayOfMonthState]) => {
      const [activeYear, activeMonth] = incompePerDayOfMonthState.activeMonthYearId.split('-');
      let activeFilter = incompePerDayOfMonthState.activeFilters.find(v => v.month === activeMonth && v.year === activeYear);
      if (!activeFilter) {
        activeFilter = incompePerDayOfMonthState.activeFilters[0];
      }
      return this.reportsService.generateIncomesByMonth(id, activeFilter).pipe(
        map((data) => {
          const fallbackName = `Operaciones-de-ingresos-por-dia-del-mes_${ incompePerDayOfMonthState.activeMonthYearId }.xlsx`;
          this._downloadAttemptForSpreadsheet(data, fallbackName);
          return generateIncomesByMonthSpreadsheetSuccess();
        }),
        catchError(error => of(generateIncomesByMonthSpreadsheetFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  generateParkingDetailsSpreadsheet$ = createEffect(() => this.actions$.pipe(
    ofType(generateParkingDetailsSpreadsheet),
    mergeMap(({ id: parkingId, start, end }) => {
      return this.reportsService.generateParkingDetails(parkingId, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')).pipe(
        map((data) => {
          const fallbackName = `Detalles-estacionamiento_${ start.format('YYYYMMDD') }-${ end.format('YYYYMMDD') }.xlsx`;
          this._downloadAttemptForSpreadsheet(data, fallbackName);
          return generateParkingDetailsSpreadsheetSuccess();
        }),
        catchError(error => of(generateParkingDetailsSpreadsheetFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  private _downloadAttemptForSpreadsheet(response: HttpResponse<Blob>, fallbackName: string): void {
    const blob = new Blob([response.body], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8'
    });
    const attachmentHeader = response.headers.get('Content-Disposition').split('filename=');
    if (attachmentHeader.length === 2) {
      fileSaver.saveAs(blob, attachmentHeader[1]);
    } else {
      fileSaver.saveAs(blob, fallbackName);
    }
  }

}
