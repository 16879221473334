import { createAction, props } from '@ngrx/store';
import { AppError } from '@shared/interfaces/app.interfaces';
import { Moment } from 'moment';

export const generateOperationsByStaySpreadsheet = createAction(
  '[Reports] Generate operations by stay spreadsheet'
);
export const generateOperationsByStaySpreadsheetSuccess = createAction(
  '[Reports] Generate operations by stay spreadsheet success'
);
export const generateOperationsByStaySpreadsheetFail = createAction(
  '[Reports] Generate operations by stay spreadsheet fail',
  props<{ error: AppError }>()
);

export const generateIncomesByMonthSpreadsheet = createAction(
  '[Reports] Generate incomes by month spreadsheet'
);
export const generateIncomesByMonthSpreadsheetSuccess = createAction(
  '[Reports] Generate incomes by month spreadsheet success'
);
export const generateIncomesByMonthSpreadsheetFail = createAction(
  '[Reports] Generate incomes by month spreadsheet fail',
  props<{ error: AppError }>()
);

export const generateParkingDetailsSpreadsheet = createAction(
  '[Reports] Generate parking details spreadsheet',
    props<{ id: string, start: Moment, end: Moment }>()
);
export const generateParkingDetailsSpreadsheetSuccess = createAction(
  '[Reports] Generate parking details spreadsheet success'
);
export const generateParkingDetailsSpreadsheetFail = createAction(
  '[Reports] Generate parking details spreadsheet fail',
  props<{ error: AppError }>()
);
