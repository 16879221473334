import { ReportsState } from '@core/reports/reports.interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import {
  generateIncomesByMonthSpreadsheet,
  generateIncomesByMonthSpreadsheetFail,
  generateIncomesByMonthSpreadsheetSuccess,
  generateOperationsByStaySpreadsheet,
  generateOperationsByStaySpreadsheetFail,
  generateOperationsByStaySpreadsheetSuccess,
  generateParkingDetailsSpreadsheet,
  generateParkingDetailsSpreadsheetFail,
  generateParkingDetailsSpreadsheetSuccess
} from '@core/reports/reports.actions';

export const initialReportsState: ReportsState = {
  loading: false
};

const _reportsReducer = createReducer(
  initialReportsState,
  on(generateOperationsByStaySpreadsheet, generateIncomesByMonthSpreadsheet, generateParkingDetailsSpreadsheet,
    (state) => ({ ...state, loading: true, error: undefined })
  ),
  on(generateOperationsByStaySpreadsheetSuccess, generateIncomesByMonthSpreadsheetSuccess, generateParkingDetailsSpreadsheetSuccess,
    (state) => ({ ...state, loading: false })
  ),
  on(generateOperationsByStaySpreadsheetFail, generateIncomesByMonthSpreadsheetFail, generateParkingDetailsSpreadsheetFail,
    (state, { error }) => ({ ...state, error, loading: false })
  )
);

export function reportsReducer(state: ReportsState, action: Action): ReportsState {
  return _reportsReducer(state, action);
}
