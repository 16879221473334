<div class="modal fade" aria-hidden="true" #modal>
  <div class="modal-dialog modal-dialog-scrollable modal-fullscreen-sm-down modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Impresiones del folio "{{ folio }}" con placa "{{ plates }}".
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center align-items-center g-0 mb-2">
          <div class="col-lg-4">
            <span class="badge bg-info">{{ data?.ticket?.length }} impresi{{ data?.ticket?.length !== 1 ? 'ones' : 'ón' }} de boleto</span>
          </div>
          <div class="col-lg-4" *ngIf="data?.receipt?.length">
            <span class="badge bg-secondary">{{ data?.receipt?.length }} impresi{{ data?.receipt?.length !== 1 ? 'ones' : 'ón' }} de recibo</span>
          </div>
          <div class="col-lg-4" *ngIf="data?.canceled?.length">
            <span class="badge bg-danger">{{ data?.canceled?.length }} impresi{{ data?.canceled?.length !== 1 ? 'ones' : 'ón' }} de cancelaciones</span>
          </div>
        </div>
        <table class="table table-striped table-bordered mt-4">
          <thead>
          <tr>
            <th scope="col">Tipo de impresión</th>
            <th scope="col">Fecha</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let scan of data?.ticket">
            <th>Boleto</th>
            <td>{{ getScanDate(scan?.printedAt) }}</td>
          </tr>
          <tr *ngFor="let scan of data?.receipt">
            <th>Recibo</th>
            <td>{{ getScanDate(scan?.printedAt) }}</td>
          </tr>
          <tr *ngFor="let scan of data?.canceled">
            <th>Cancelación</th>
            <td>{{ getScanDate(scan?.printedAt) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
