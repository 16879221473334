import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ParkingService } from '@core/parking/parking.service';
import { HelperService } from '@shared/services/helper.service';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import {
  getParkingDetails, getParkingDetailsFail, getParkingDetailsSuccess,
  getParkingOverview, getParkingOverviewFail, getParkingOverviewSuccess,
  getParkingsInformation,
  getParkingsInformationFail,
  getParkingsInformationSuccess
} from '@core/parking/parking.actions';
import { combineLatest, Observable, of, concat } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getParkingsState } from '@core/parking/parking.selectors';
import { ParkingDetails, ParkingPricesOverview } from '@core/parking/parking.interfaces';
import moment from 'moment';
import { selectModuleFromSessionAllowed } from '@core/session/session.selectors';

@Injectable({
  providedIn: 'root'
})
export class ParkingEffects {

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private parkingService: ParkingService,
    private helperService: HelperService
  ) {}

  getParkingsInformation$ = createEffect(
    () => this.actions$.pipe(
      ofType(getParkingsInformation),
      mergeMap(() => {
        return this.parkingService.getParkingsInformation().pipe(
          map((data) => {
            return getParkingsInformationSuccess({ parkingsData: data });
          }),
          catchError(error => of(getParkingsInformationFail({ error: this.helperService.parseHttpError(error) })))
        );
      })
    )
  );

  getParkingOverview$ = createEffect(
    () => this.actions$.pipe(
      ofType(getParkingOverview),
      concatLatestFrom(() => [
        this.store.pipe(select(getParkingsState)),
        this.store.pipe(select(selectModuleFromSessionAllowed('parkings/overview'))),
        this.store.pipe(select(selectModuleFromSessionAllowed('parkings/prices')))
      ]),
      mergeMap(([action, parkingsData, overviewAllowed, pricesAllowed]) => {
        const parkingOverview = parkingsData[action.id] ? parkingsData[action.id].overview : undefined;
        const parkingPricesOverview: ParkingPricesOverview | undefined = !parkingOverview ? undefined : {
          address: parkingOverview.address,
          prices: parkingOverview.prices,
          promotions: parkingOverview.promotions,
          validations: parkingOverview.validations
        };
        const start = action.dateRanges ? action.dateRanges.start : moment().format('YYYY-MM-DD');
        const end = action.dateRanges ? action.dateRanges.end : moment().format('YYYY-MM-DD');
        if (parkingPricesOverview) {
          if (!overviewAllowed) {
            return of(getParkingOverviewFail({ id: action.id, error: this.helperService.generateUnAllowedError() }));
          }
          return this.parkingService.getParkingOverview(action.id, start, end).pipe(
            map((overview) => {
              return getParkingOverviewSuccess({ id: action.id, parkingOverview: { ...overview, ...parkingPricesOverview } });
            }),
            catchError(error => of(getParkingOverviewFail({ id: action.id, error: this.helperService.parseHttpError(error) })))
          );
        }
        if (!overviewAllowed || !pricesAllowed) {
          return of(getParkingOverviewFail({ id: action.id, error: this.helperService.generateUnAllowedError() }));
        }
        return combineLatest([
          this.parkingService.getParkingOverview(action.id, start, end),
          this.parkingService.getParkingPricesOverview(action.id)
        ]).pipe(
          map(([overview, prices]) => {
            return getParkingOverviewSuccess({ id: action.id, parkingOverview: { ...overview, ...prices } });
          }),
          catchError(error => of(getParkingOverviewFail({ id: action.id, error: this.helperService.parseHttpError(error) })))
        );
      })
    )
  );

  getParkingDetails$ = createEffect(
    () => this.actions$.pipe(
      ofType(getParkingDetails),
      concatLatestFrom(() => [
        this.store.pipe(select(getParkingsState)),
        this.store.pipe(select(selectModuleFromSessionAllowed('parkings/summary'))),
        this.store.pipe(select(selectModuleFromSessionAllowed('parkings/tickets'))),
        this.store.pipe(select(selectModuleFromSessionAllowed('parkings/pensions')))
      ]),
      mergeMap(([action, summaryAllowed, ticketsAllowed, pensionsAllowed]) => {
        if (!summaryAllowed && !ticketsAllowed && !pensionsAllowed) {
          return of(getParkingDetailsFail({ id: action.id, error: this.helperService.generateUnAllowedError() }));
        }
        let parkingDetails: Partial<ParkingDetails> = {};
        const requests: Observable<unknown>[] = [];
        if (summaryAllowed) {
          requests.push(
            this.parkingService.getParkingSummary(action.id, action.start, action.end).pipe(
              map(summary => parkingDetails = {...parkingDetails, summary })
            )
          );
        }
        if (ticketsAllowed) {
          requests.push(
            this.parkingService.getParkingTickets(action.id, action.start, action.end).pipe(
              map(tickets => parkingDetails = {...parkingDetails, tickets })
            )
          );
        }
        if (pensionsAllowed) {
          requests.push(
            this.parkingService.getParkingPensions(action.id, action.start, action.end).pipe(
              map(pensions => parkingDetails = {...parkingDetails, pensions })
            )
          );
        }
        return concat(...requests).pipe(
          toArray(),
          map(() => {
            return getParkingDetailsSuccess({ id: action.id, parkingDetails });
          }),
          catchError(error => of(getParkingDetailsFail({ id: action.id, error: this.helperService.parseHttpError(error) })))
        );
      })
    )
  );

}
