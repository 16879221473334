import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap';
import { ScansDetailForTicket } from '@core/parking/parking.interfaces';
import * as moment from 'moment';

@Component({
  selector: 'yw-folio-scan-count-modal',
  templateUrl: './folio-scan-count-modal.component.html',
  styleUrls: ['./folio-scan-count-modal.component.scss']
})
export class FolioScanCountModalComponent implements AfterViewInit {
  @ViewChild('modal') modalElement: ElementRef<HTMLDivElement>;
  @Output() hiddenModal = new EventEmitter<void>();
  modalInstance: Modal;
  folio = '';
  plates = '';
  data?: ScansDetailForTicket;

  ngAfterViewInit(): void {
    this.modalInstance = new Modal(this.modalElement.nativeElement);
    this.modalElement.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.hiddenModal.emit();
    });
  }

  getScanMethod(method: 'Scanner' | 'Plates' | 'Unknown' | string): string {
    switch (method) {
      case 'Scanner':
        return 'Escáner';
      case 'Plates':
        return 'Placas';
      default:
        return 'Desconocido';
    }
  }

  getScanDate(date: string): string {
    return moment(date, 'YYYY-MM-DD[T]HH:mm:ss.SSSSSSZZ').format('DD/MM/YYYY[ - ]HH:mm');
  }

  showModal(): void {
    this.modalInstance.show();
  }

}
