<h1 mat-dialog-title>Cambiar la contraseña</h1>
<div mat-dialog-content>
  <p>Usuario: {{ userName }}</p>
  <form [formGroup]="changePassForm">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Contraseña actual</mat-label>
      <input type="password" autocomplete="current-password" matInput formControlName="currentPass">
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Nueva contraseña</mat-label>
      <input type="password" autocomplete="new-password" matInput formControlName="newPass">
      <mat-error *ngIf="changePassForm.get('newPass').hasError('minlength')">La contraseña debe ser mínimo 6 caracteres</mat-error>
      <mat-error *ngIf="changePassForm.get('newPass').hasError('maxlength')">La contraseña debe ser máximo 30 caracteres</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent" >
      <mat-label>Repite nueva contraseña</mat-label>
      <input type="password" autocomplete="new-password" matInput formControlName="confirmPass">
      <mat-error *ngIf="changePassForm.get('confirmPass').hasError('confirmNotEqual')">Las contraseñas no coinciden</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button (click)="close()" [disabled]="isLoading">Cancelar</button>
  <button mat-flat-button *ngIf="!isLoading" (click)="attemptToChangePassword()" color="primary">Cambiar</button>
  <div *ngIf="isLoading" class="spinner-grow text-primary" role="status"><span class="sr-only visually-hidden">Loading...</span></div>
</div>
