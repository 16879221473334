import { Injectable } from '@angular/core';
import { StatisticsService } from '@core/statistics/statistics.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { FilterDataRequested, MonthlyFilterData } from '@core/statistics/interfaces/statistics.interfaces';
import { environment } from '@environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private httpClient: HttpClient) {}

  generateOperationsByStay(parkingId: string, filter: FilterDataRequested): Observable<HttpResponse<Blob>> {
    const params = StatisticsService.generateParamsFromFilter(filter).appendAll({ type: 'operations-by-stay' });
    const headers = new HttpHeaders().append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    return this.httpClient.get(`${ environment.apiUrl }/parkings/${ parkingId }/admin/stats`, {
      params, headers, observe: 'response', responseType: 'blob'
    });
  }

  generateIncomesByMonth(parkingId: string, filter: MonthlyFilterData): Observable<HttpResponse<Blob>> {
    const momentDate = moment();
    momentDate.set({ month: parseInt(filter.month, 10) - 1, year: parseInt(filter.year, 10) });
    const params = new HttpParams().appendAll({
      type: 'incomes-by-month',
      from: `${ momentDate.format('YYYY-MM') }-01`
    });
    const headers = new HttpHeaders().append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    return this.httpClient.get(`${ environment.apiUrl }/parkings/${ parkingId }/admin/stats`, {
      params, headers, observe: 'response', responseType: 'blob'
    });
  }

  generateParkingDetails(parkingId: string, start: string, end: string): Observable<HttpResponse<Blob>> {
    const params = new HttpParams().append('from', start).append('to', end);
    const headers = new HttpHeaders().append('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    // TODO change endpoint for details
    return this.httpClient.get(`${ environment.apiUrl }/parkings/${ parkingId }/admin/details`, {
      params, headers, observe: 'response', responseType: 'blob'
    });
  }
}
