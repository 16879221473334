import { Action, createReducer, on } from '@ngrx/store';
import { ParkingState, ParkingDetails } from '@core/parking/parking.interfaces';
import {
  getParkingDetails,
  getParkingDetailsFail,
  getParkingDetailsSuccess,
  getParkingOverview,
  getParkingOverviewFail,
  getParkingOverviewSuccess,
  getParkingsInformation,
  getParkingsInformationFail,
  getParkingsInformationSuccess
} from '@core/parking/parking.actions';
import { HelperService } from '@shared/services/helper.service';
import { cloneDeep } from 'lodash';

export const initialParkingState: ParkingState = {
  companyData: undefined,
  lastUpdated: undefined,
  parkings: {},
  loading: false
};

const initialParkingDetails: ParkingDetails = {
  pensions: [],
  tickets: [],
  summary: {
    tickets: 0,
    total: 0,
    list: []
  }
};

const _parkingsReducer = createReducer(
  initialParkingState,
  on(getParkingsInformation, (oldState: ParkingState): ParkingState => {
    return { ...oldState, loading: true, error: undefined };
  }),
  on(getParkingsInformationSuccess, (oldState: ParkingState, { parkingsData }): ParkingState => {
    return { ...oldState, lastUpdated: HelperService.getNowDateTime(), companyData: parkingsData, loading: false };
  }),
  on(getParkingsInformationFail, (oldState: ParkingState, { error }): ParkingState => {
    return { ...oldState, error, loading: false };
  }),
  on(getParkingOverview, getParkingDetails, (oldState: ParkingState, { id }): ParkingState => {
    return {
      ...oldState,
      parkings: { ...oldState.parkings, [id]: { ...oldState.parkings[id], loading: true, error: undefined } },
      loading: true,
      error: undefined
    };
  }),
  on(getParkingOverviewSuccess, (oldState: ParkingState, { id, parkingOverview }): ParkingState => {
    const hasPromotions = parkingOverview.promotions.length > 0;
    const validations = parkingOverview.validations;
    const hasValidatorNames = !!validations?.unique.some(v => !!v.validator);
    const hasValidations = validations.repeatable.length + validations.unique.length > 0;
    return {
      ...oldState,
      lastUpdated: HelperService.getNowDateTime(),
      parkings: { ...oldState.parkings, [id]: {
        ...oldState.parkings[id],
        hasPromotions,
        hasValidations,
        hasValidatorNames,
        overview: parkingOverview,
        loading: false
      } },
      loading: false
    };
  }),
  on(getParkingDetailsSuccess, (oldState: ParkingState, { id, parkingDetails: { pensions, summary, tickets } }): ParkingState => {
    const selectedParking = oldState.parkings[id];
    const allParamsAreUndefined = pensions === undefined && summary === undefined && tickets === undefined;
    if (!selectedParking || allParamsAreUndefined) {
      return oldState;
    }
    const selectedParkingDetails = cloneDeep(selectedParking.details ?? initialParkingDetails);
    if (pensions !== undefined) {
      selectedParkingDetails.pensions = pensions;
    }
    if (summary !== undefined) {
      selectedParkingDetails.summary = summary;
    }
    if (tickets !== undefined) {
      selectedParkingDetails.tickets = tickets;
    }
    return {
      ...oldState,
      lastUpdated: HelperService.getNowDateTime(),
      parkings: { ...oldState.parkings, [id]: { ...oldState.parkings[id], details: selectedParkingDetails, loading: false } },
      loading: false
    };
  }),
  on(getParkingOverviewFail, getParkingDetailsFail, (oldState: ParkingState, { id, error }): ParkingState => {
    return {
      ...oldState,
      parkings: { ...oldState.parkings, [id]: { ...oldState.parkings[id], loading: false, error } },
      loading: false,
      error
    };
  })
);

export function parkingsReducer(state: ParkingState, action: Action): ParkingState {
  return _parkingsReducer(state, action);
}
