import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { StatisticsService } from '@core/statistics/statistics.service';
import { HelperService } from '@shared/services/helper.service';
import {
  retrieveAverageOccupancyData,
  retrieveAverageOccupancyDataFail,
  retrieveAverageOccupancyDataSuccess,
  retrieveEmployeeOperationsData,
  retrieveEmployeeOperationsDataFail,
  retrieveEmployeeOperationsDataSuccess,
  retrieveFrequentCustomersData,
  retrieveFrequentCustomersDataFail,
  retrieveFrequentCustomersDataSuccess,
  retrieveIncomePerDayOfMonthData,
  retrieveIncomePerDayOfMonthDataFail,
  retrieveIncomePerDayOfMonthDataSuccess,
  retrieveOperationsByStayData,
  retrieveOperationsByStayDataFail,
  retrieveOperationsByStayDataSuccess,
  retrievePercentOccupancyData,
  retrievePercentOccupancyDataFail,
  retrievePercentOccupancyDataSuccess,
  setActiveIncomePerDayOfMonthData
} from '@core/statistics/statistics.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getActiveStatisticsParkingIdState, getFrequentClient } from '@core/statistics/statistics.selectors';
import { selectModuleFromSessionAllowed } from '@core/session/session.selectors';

@Injectable({
  providedIn: 'root'
})
export class StatisticsEffects {

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private statisticsService: StatisticsService,
    private helperService: HelperService
  ) {}

  retrieveIncomePerDayOfMonthData$ = createEffect(() => this.actions$.pipe(
    ofType(retrieveIncomePerDayOfMonthData),
    concatLatestFrom(() => [
      this.store.pipe(select(getActiveStatisticsParkingIdState)),
      this.store.pipe(select(selectModuleFromSessionAllowed('parkings/stats:incomes-by-month')))
    ]),
    mergeMap(([action, id, statsAllowed]) => {
      if (!statsAllowed) {
        return of(retrieveIncomePerDayOfMonthDataFail({ error: this.helperService.generateUnAllowedError() }));
      }
      return this.statisticsService.getIncomesByMonth(id, action.filter).pipe(
        mergeMap((data) => {
          return [
            retrieveIncomePerDayOfMonthDataSuccess({ data, filter: action.filter }),
            setActiveIncomePerDayOfMonthData({ activeMonthYearId: `${ action.filter.year }-${ action.filter.month }` })
          ];
        }),
        catchError(error => of(retrieveIncomePerDayOfMonthDataFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  retrieveAverageOccupancyData$ = createEffect(() => this.actions$.pipe(
    ofType(retrieveAverageOccupancyData),
    concatLatestFrom(() => [
      this.store.pipe(select(getActiveStatisticsParkingIdState)),
      this.store.pipe(select(selectModuleFromSessionAllowed('parkings/stats:average-occupancy')))
    ]),
    mergeMap(([action, id, statsAllowed]) => {
      if (!statsAllowed) {
        return of(retrieveAverageOccupancyDataFail({ error: this.helperService.generateUnAllowedError() }));
      }
      return this.statisticsService.getAverageOccupancy(id, action.filter).pipe(
        map((data) => {
          return retrieveAverageOccupancyDataSuccess({ data, filter: action.filter });
        }),
        catchError(error => of(retrieveAverageOccupancyDataFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  retrievePercentOccupancyData$ = createEffect(() => this.actions$.pipe(
    ofType(retrievePercentOccupancyData),
    concatLatestFrom(() => [
      this.store.pipe(select(getActiveStatisticsParkingIdState)),
      this.store.pipe(select(selectModuleFromSessionAllowed('parkings/stats:percent-occupancy')))
    ]),
    mergeMap(([action, id, statsAllowed]) => {
      if (!statsAllowed) {
        return of(retrievePercentOccupancyDataFail({ error: this.helperService.generateUnAllowedError() }));
      }
      return this.statisticsService.getPercentOccupancy(id, action.filter).pipe(
        map((data) => {
          return retrievePercentOccupancyDataSuccess({ data, filter: action.filter });
        }),
        catchError(error => of(retrievePercentOccupancyDataFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  retrieveEmployeeOperationsData$ = createEffect(() => this.actions$.pipe(
    ofType(retrieveEmployeeOperationsData),
    concatLatestFrom(() => [
      this.store.pipe(select(getActiveStatisticsParkingIdState)),
      this.store.pipe(select(selectModuleFromSessionAllowed('parkings/stats:employee-operations')))
    ]),
    mergeMap(([action, id, statsAllowed]) => {
      if (!statsAllowed) {
        return of(retrieveEmployeeOperationsDataFail({ error: this.helperService.generateUnAllowedError() }));
      }
      return this.statisticsService.getEmployeeOperations(id, action.filter).pipe(
        map((data) => {
          return retrieveEmployeeOperationsDataSuccess({ data, filter: action.filter });
        }),
        catchError(error => of(retrieveEmployeeOperationsDataFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  retrieveOperationsByStayData$ = createEffect(() => this.actions$.pipe(
    ofType(retrieveOperationsByStayData),
    concatLatestFrom(() => [
      this.store.pipe(select(getActiveStatisticsParkingIdState)),
      this.store.pipe(select(selectModuleFromSessionAllowed('parkings/stats:operations-by-stay')))
    ]),
    mergeMap(([action, id, statsAllowed]) => {
      if (!statsAllowed) {
        return of(retrieveOperationsByStayDataFail({ error: this.helperService.generateUnAllowedError() }));
      }
      return this.statisticsService.getOperationsByStay(id, action.filter).pipe(
        map((data) => {
          return retrieveOperationsByStayDataSuccess({ data, filter: action.filter });
        }),
        catchError(error => of(retrieveOperationsByStayDataFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

  retrieveFrequentCustomersData$ = createEffect(() => this.actions$.pipe(
    ofType(retrieveFrequentCustomersData),
    concatLatestFrom(() => [
      this.store.pipe(select(getActiveStatisticsParkingIdState)),
      this.store.pipe(select(getFrequentClient)),
      this.store.pipe(select(selectModuleFromSessionAllowed('parkings/stats:frequent-customers')))
    ]),
    mergeMap(([action, id, frequentClientData, statsAllowed]) => {
      if (!statsAllowed) {
        return of(retrieveFrequentCustomersDataFail({ error: this.helperService.generateUnAllowedError() }));
      }
      const currentFilter = action.filter || frequentClientData.activeFilter;
      const currentMinFrequency = action.minimumFrequency || frequentClientData.activeMinimumFrequencyCriteria;
      return this.statisticsService.getFrequentCustomers(id, currentFilter, currentMinFrequency).pipe(
        map((data) => {
          return retrieveFrequentCustomersDataSuccess({ data, filter: currentFilter, minimumFrequency: currentMinFrequency });
        }),
        catchError(error => of(retrieveFrequentCustomersDataFail({ error: this.helperService.parseHttpError(error) })))
      );
    })
  ));

}
