import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { externalModules } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { authenticationReducer, clearStateOnLogoutReducer } from '@core/session/session.reducer';
import { SessionEffects } from '@core/session/session.effects';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { parkingsReducer } from '@core/parking/parking.reducer';
import { ParkingEffects } from '@core/parking/parking.effects';
import { AuthenticationInterceptor } from '@core/session/authentication.interceptor';
import { AlertComponent } from '@components/alert/alert.component';
import { statisticsReducer } from '@core/statistics/statistics.reducer';
import { StatisticsEffects } from '@core/statistics/statistics.effects';
import { FolioScanCountModalComponent } from '@components/folio-scan-count-modal/folio-scan-count-modal.component';
import { ChangePasswordDialogComponent } from '@components/change-password-dialog/change-password-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { PrintCountModalComponent } from '@components/print-count-modal/print-count-modal.component';
import { reportsReducer } from '@core/reports/reports.reducer';
import { ReportsEffects } from '@core/reports/reports.effects';
import { CommentsModalComponent } from './components/comments-modal/comments-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    AlertComponent,
    FolioScanCountModalComponent,
    ChangePasswordDialogComponent,
    PrintCountModalComponent,
    CommentsModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({
      session: authenticationReducer,
      parkings: parkingsReducer,
      statistics: statisticsReducer,
      reports: reportsReducer
    }, {
      metaReducers: [clearStateOnLogoutReducer],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([
      SessionEffects,
      ParkingEffects,
      StatisticsEffects,
      ReportsEffects
    ]),
    HttpClientModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    ...externalModules
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
