import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap';

@Component({
  selector: 'yw-comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements AfterViewInit {
  @ViewChild('modal') modalElement: ElementRef<HTMLDivElement>;
  @Output() hiddenModal = new EventEmitter<void>();
  modalInstance: Modal;

  folio = '';
  plates = '';
  private _comments = '';

  ngAfterViewInit(): void {
    this.modalInstance = new Modal(this.modalElement.nativeElement);
    this.modalElement.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.hiddenModal.emit();
    });
  }

  showModal(): void {
    this.modalInstance.show();
  }

  set comments(comments: string) {
    this._comments = comments.trim();
  }

  get comments(): string {
    return this._comments;
  }

}
